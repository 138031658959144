/* EVENTS */
export const GET_EVENTS = 'GET_EVENTS';
export const GET_EVENTS_SUCCESS = 'GET_EVENTS_SUCCESS';
export const GET_EVENTS_FAIL = 'GET_EVENTS_FAIL';

export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_FAIL = 'GET_CATEGORIES_FAIL';

export const GET_UPCOMMINGEVENT = 'GET_UPCOMMINGEVENT';
export const GET_UPCOMMINGEVENT_SUCCESS = 'GET_UPCOMMINGEVENT_SUCCESS';
export const GET_UPCOMMINGEVENT_FAIL = 'GET_UPCOMMINGEVENT_FAIL';

export const ADD_NEW_EVENT = 'ADD_NEW_EVENT';
export const ADD_EVENT_SUCCESS = 'ADD_EVENT_SUCCESS';
export const ADD_EVENT_FAIL = 'ADD_EVENT_FAIL';

export const UPDATE_EVENT = 'UPDATE_EVENT';
export const UPDATE_EVENT_SUCCESS = 'UPDATE_EVENT_SUCCESS';
export const UPDATE_EVENT_FAIL = 'UPDATE_EVENT_FAIL';

export const DELETE_EVENT = 'DELETE_EVENT';
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS';
export const DELETE_EVENT_FAIL = 'DELETE_EVENT_FAIL';

export const RESET_CALENDAR = 'RESET_CALENDAR';
